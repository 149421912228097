// Copyright 2024 Merit International Inc. All Rights Reserved

import { Heading, Icon, useTheme } from "@merit/frontend-components";
import { HelpLink } from "./HelpLink";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer } from "../Spacer";
import { Image, StyleSheet, TouchableHighlight, View } from "react-native";
import { NavLink } from "./NavLink";
import { NavPopoverLink } from "./NavPopoverLink";
import { NavText } from "./NavText";
import { Popover } from "../Popover/Popover";
import { VERSION } from "../../constants/version";
import { useAcceptedFolioStore } from "../../stores/acceptedFolioStore";
import { useApi } from "../../api/api";
import { useAppConstantsStore, useConfigurationStore } from "../../stores";
import { useAuthStore } from "../../stores/authStore";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useLogout } from "../../utils/useLogout";
import { useNavigation, useRoute } from "@react-navigation/native";
import React, { useEffect, useRef, useState } from "react";
import type { IconName } from "@merit/frontend-components";
import type { LDFeatureFlags } from "../../configuration/featureFlags";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { OrgsGet200Response } from "../../gen/org-portal";
import type { RouteParams } from "../../Router";

const screenName = "Nav";

const generateDivTestProps = Helpers.generateDivTestProps;

type PopoverNavName = "LogOut" | "MyMerits" | "OrgRegistration" | "OrgSelect";
type PopoverNavOption = {
  readonly icon: IconName;
  readonly name: PopoverNavName;
  readonly title: string;
};

const popoverNavOptions: readonly PopoverNavOption[] = [
  {
    icon: "switchMediumDefault",
    name: "OrgSelect",
    title: "Switch organizations",
  },
  {
    icon: "closeCircleMediumDefault",
    name: "OrgRegistration",
    title: "Create another organization",
  },
  {
    icon: "arrowExpandMediumDefault",
    name: "MyMerits",
    title: "My merits",
  },
  {
    icon: "logoutMediumDefault",
    name: "LogOut",
    title: "Log out",
  },
];

export const Nav = () => {
  const route = useRoute();
  const { theme } = useTheme();
  const navigation = useNavigation<NativeStackNavigationProp<RouteParams>>();
  const appConstants = useAppConstantsStore();
  const configStore = useConfigurationStore();
  const { logout } = useLogout();
  const popoverOriginRef = useRef<HTMLDivElement>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [navExpanded, setNavExpanded] = useState<boolean>(true);
  const { showAutoMappingFeature, showPoliciesFrontend, showStudioFrontend } =
    useFlags<LDFeatureFlags>();

  const {
    clear: clearAcceptedAccountFolioStore,
    setUserAcceptedAccountFolio,
    userAcceptedAccountFolio,
  } = useAcceptedFolioStore();
  const {
    profile,
    selectedOrgId,
    selectedOrgName,
    setSelectedOrgId,
    setSelectedOrgName,
    userCanRegisterOrgs,
  } = useAuthStore();
  const { api } = useApi();

  const disableNav = !userAcceptedAccountFolio && route.name === "OrgSettings";
  const styles = StyleSheet.create({
    container: {
      backgroundColor: "white",
      borderColor: theme.colors.border.default,
      borderRightWidth: 1,
    },
    expandButton: {
      alignItems: "center",
      borderColor: theme.colors.border.default,
      borderRadius: theme.borderRadii.s,
      borderWidth: 1,
      height: 40,
      justifyContent: "center",
      width: 40,
    },
    header: {
      alignItems: "center",
      borderBottomWidth: 1,
      borderColor: "rgba(0,0,0,0.2)",
      flexDirection: "row",
      height: 72,
      justifyContent: "space-between",
      paddingHorizontal: theme.spacing.xxl,
      paddingVertical: theme.spacing.l,
      position: "relative",
    },
  });

  const handleOrgSelect = () => {
    setSelectedOrgName(null);
    setSelectedOrgId(null);
    clearAcceptedAccountFolioStore();
  };

  const handlePopoverLinkPress = (linkName: PopoverNavName) => {
    setIsPopoverOpen(false);
    switch (linkName) {
      case "LogOut":
        logout();
        break;
      case "OrgRegistration":
        navigation.navigate("OrgRegistration");
        break;
      case "OrgSelect":
        handleOrgSelect();
        navigation.navigate("OrgSelect");
        break;
      case "MyMerits":
        window.open("https://member.merits.com/login", "_blank");
        break;
      default:
        navigation.navigate(linkName);
    }
  };

  useEffect(() => {
    const TWO_MINUTES_MS = 1000 * 60 * 2;
    const checkFolioAcceptanceState = () => {
      if (
        userAcceptedAccountFolio ||
        selectedOrgId === null ||
        configStore.configuration === undefined
      ) {
        return;
      }
      const promise = api.getContainers({
        limit: appConstants.folioInboxLimit,
        orgID: selectedOrgId,
        recipientID: selectedOrgId,
        state: "pending",
        templateID: configStore.configuration.accountFolioTemplateUUID,
        templateType: "Folio",
      });
      promise.then((value: OrgsGet200Response) => {
        const numContainers = value.containers?.length ?? 0;
        setUserAcceptedAccountFolio(numContainers === 0);
        if (numContainers > 0) {
          // if we have any account/org folios that are not accepted, schedule this function to run again in ~2 minutes.
          setTimeout(checkFolioAcceptanceState, TWO_MINUTES_MS);
        }
      });
    };

    checkFolioAcceptanceState();
  }, [
    setUserAcceptedAccountFolio,
    userAcceptedAccountFolio,
    configStore,
    selectedOrgId,
    appConstants.folioInboxLimit,
    api,
  ]);

  return (
    <>
      <View style={styles.container}>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            transition: "width 300ms",
            transitionTimingFunction: "ease-out",
            width: navExpanded ? 250 : 72,
          }}
        >
          <View style={styles.header}>
            <View style={{ flexDirection: "row" }}>
              <Image
                source={theme.images.orgLogo}
                style={{ height: 20, resizeMode: "contain", width: 20 }}
              />
              {navExpanded && (
                <>
                  <HorizontalSpacer />
                  <View style={{ maxWidth: 150, overflow: "hidden" }}>
                    <Heading level="4" numberOfLines={1}>
                      {selectedOrgName}
                    </Heading>
                  </View>
                </>
              )}
            </View>

            {navExpanded && (
              <div
                onClick={() => {
                  setIsPopoverOpen(prevState => !prevState);
                }}
                ref={popoverOriginRef}
                style={{ cursor: "pointer" }}
                {...generateDivTestProps({
                  componentName: "NavPopoverIcon",
                  testID: "chevronDown",
                })}
              >
                <Icon name="chevronDownMediumDefault" />
              </div>
            )}
          </View>

          <NavLink
            disabled={disableNav}
            expanded={navExpanded}
            iconName={null}
            isActive={route.name === "Datasources"}
            testProps={{ elementName: "DatasourcesNavLink", screenName }}
            title="Data Sources"
            to="Datasources"
          />

          {showAutoMappingFeature && (
            <NavLink
              disabled={disableNav}
              expanded={navExpanded}
              iconName={null}
              isActive={route.name === "AutoMapTemplate"}
              testProps={{ elementName: "AutoMapTemplateNavLink", screenName }}
              title="Automap Template"
              to="AutoMapTemplate"
            />
          )}

          <NavLink
            disabled={disableNav}
            expanded={navExpanded}
            iconName={null}
            isActive={route.name === "Fields"}
            testProps={{ elementName: "FieldsNavLink", screenName }}
            title="Fields"
            to="Fields"
          />

          <NavLink
            disabled={disableNav}
            expanded={navExpanded}
            iconName={null}
            isActive={route.name === "Templates"}
            testProps={{ elementName: "TemplatesNav", screenName }}
            title="Templates"
            to="Templates"
          />

          <NavLink
            disabled={disableNav}
            expanded={navExpanded}
            iconName={null}
            isActive={route.name === "Approvals"}
            testProps={{ elementName: "ApprovalsNavLink", screenName }}
            title="Approvals"
            to="Approvals"
          />

          <NavLink
            disabled={disableNav}
            expanded={navExpanded}
            iconName={null}
            isActive={route.name === "Records"}
            testProps={{ elementName: "RecordsNavLink", screenName }}
            title="Records"
            to="Records"
          />

          {showPoliciesFrontend && (
            <NavLink
              disabled={disableNav}
              expanded={navExpanded}
              iconName={null}
              isActive={route.name === "Policies"}
              testProps={{ elementName: "PoliciesNavLink", screenName }}
              title="Policies"
              to="Policies"
            />
          )}

          {showStudioFrontend && (
            <NavLink
              disabled={disableNav}
              expanded={navExpanded}
              iconName={null}
              isActive={route.name === "Studio"}
              testProps={{ elementName: "StudioNavLink", screenName }}
              title="Studio"
              to="Studio"
            />
          )}

          <View style={{ flex: 1 }} />

          <NavLink
            disabled={false}
            expanded={navExpanded}
            iconName={userAcceptedAccountFolio ? null : "errorMediumWarning"}
            isActive={route.name === "OrgSettings"}
            testProps={{ elementName: "OrgSettingsNavLink", screenName }}
            title="Settings"
            to="OrgSettings"
            type="small"
          />

          <HelpLink
            expanded={navExpanded}
            iconName={null}
            isActive={route.name === "Help"}
            testProps={{ elementName: "HelpNavLink", screenName }}
            title="Help"
            type="small"
          />

          <NavText
            testProps={{ elementName: "Version", screenName }}
            title={VERSION}
            type="small"
          />

          <View style={{ padding: theme.spacing.l }}>
            <TouchableHighlight
              onPress={() => {
                setNavExpanded(!navExpanded);
              }}
              style={styles.expandButton}
            >
              <View>
                <Icon name="chevronLeftMediumAction" />
              </View>
            </TouchableHighlight>
          </View>
        </div>
      </View>

      {isPopoverOpen && (
        <Popover
          originElement={popoverOriginRef.current === null ? undefined : popoverOriginRef.current}
          placement="right-end"
          testID="NavPopover"
        >
          <View style={{ paddingVertical: 6 }}>
            {popoverNavOptions.map(navOption => {
              if (navOption.name === "OrgRegistration" && !userCanRegisterOrgs) {
                return <></>;
              }

              return (
                <NavPopoverLink
                  iconName={navOption.icon}
                  isActive={false}
                  key={navOption.title}
                  onPress={() => {
                    handlePopoverLinkPress(navOption.name);
                  }}
                  testProps={{ elementName: `${navOption.title}NavPopoverLink`, screenName }}
                  title={
                    navOption.name === "MyMerits"
                      ? `${navOption.title} | ${profile?.nickname ?? ""}`
                      : navOption.title
                  }
                />
              );
            })}
          </View>
        </Popover>
      )}
    </>
  );
};
